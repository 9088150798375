import {frameActionMiddleware} from "atg-store-addons";
import configureStore from "@atg-shared/micro-frontend/configureStore";
import createReducer from "./rootReducer";

const STORE_NAME = "atg-shop";

// Generally speaking, don't use this store directly – instead use Redux hooks to access the store state, etc.
const {store, filteredActionLog, persistor} = configureStore({
    name: STORE_NAME,
    createReducer,
    middlewares: [frameActionMiddleware],
});

// eslint-disable-next-line no-underscore-dangle
window._shopStore = store;

export {store, filteredActionLog, persistor};
